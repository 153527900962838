<template>
  <div></div>
</template>

<script>
export default {
  mounted() {
    this.sockets.unsubscribe(`wallet-updated`);
    this.sockets.unsubscribe(`mock-wallet-updated`);

    this.sockets.unsubscribe(`mock-bot-player-BACCARAT-live`);
    this.sockets.unsubscribe(`mock-bot-player-ROULETTE-live`);
    this.sockets.unsubscribe(`mock-bot-player-DRAGON_TIGER-live`);

    this.sockets.unsubscribe(`bot-player-BACCARAT-live`);
    this.sockets.unsubscribe(`bot-player-ROULETTE-live`);
    this.sockets.unsubscribe(`bot-player-DRAGON_TIGER-live`);

    this.sockets.unsubscribe(`game-BACCARAT-live`);
    this.sockets.unsubscribe(`game-ROULETTE-live`);
    this.sockets.unsubscribe(`game-DRAGON_TIGER-live`);

    this.$store.dispatch("logout").then(() => {
      this.$router.push("/login").then(() => {
        // window.location.reload();
      });
    });
  },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="scss">
body {
  background-color: #ffffff;
}
</style>
